import { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Input,
  message,
  Typography,
  Select,
  Space,
  Col,
} from "antd";
import Icon, {
  DeleteOutlined,
  PlusOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { FaTelegramPlane } from "react-icons/fa";
import { useAuth } from "../../../context/amplify";
import { API } from "aws-amplify";
const { Text } = Typography;

export const AllowList = (props) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [show, setShow] = useState();
  const [form] = Form.useForm();
  const auth = useAuth();
  const { chatbot } = props;

  const handleSubmit = async (data) => {
    console.log(data.number);
    let arr = [];
    data.number.forEach((e) =>
      arr.push(e.type === "whatsapp:" ? "whatsapp:" + e.number : "" + e.number)
    );
    data["number"] = arr;

    setSubmitLoading(true);
    if (data?.id) {
      await API.put(
        process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT_NAME,
        "/update/number/" + data.id,
        {
          body: {
            number: data.number,
            type: data.type,
            chatbot_id: data.chatbot_id,
          },
        }
      )
        .then(async (response) => {
          message.success("Números editados com sucesso!");

          await API.post(
            process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT_NAME,
            "/create/audits",
            {
              body: {
                name: "Editou um número",
                description: `${auth.userData.email} editou o número de chatbot ${data.number} com o tipo ${data.type}, para o chatbot ${chatbot.name}.`,
                username: auth.userData.email,
              },
            }
          );

          setSubmitLoading(false);
          setShow(false);
        })
        .catch((error) => {
          setSubmitLoading(false);
          message.error("Erro ao tentar editar lista de números...");
        });
    } else {
      await API.post(
        process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT_NAME,
        "/create/number",
        {
          body: {
            number: data.number,
            type: data.type,
            chatbot_id: chatbot.id,
          },
        }
      )
        .then(async (response) => {
          message.success("Números adicionados com sucesso!");

          await API.post(
            process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT_NAME,
            "/create/audits",
            {
              body: {
                name: "Adicionou a lista de números",
                description: `${auth.userData.email} adicionou a lista dos números ${data.number} com o tipo ${data.type}.`,
                username: auth.userData.email,
              },
            }
          );

          setSubmitLoading(false);
          setShow(false);
          form.resetFields();
        })
        .catch((error) => {
          setSubmitLoading(false);
          message.error("Erro ao tentar criar lista de números...");
        });
    }
  };

  const getData = async () => {
    const { data } = await API.get(
      process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT_NAME,
      "/read/number/0"
    );

    let obj = data.Items.filter((i) => i?.chatbot_id === chatbot?.id)[0];

    setFormValue(obj);
  };

  async function setFormValue(data) {
    let arr = [];

    console.log(data.number);

    data?.number.forEach((e) =>
      arr.push({
        type: e.split(":")[0] === "whatsapp" ? "whatsapp:" : "telegram",
        number: e?.includes(":") ? e.split(":")[1] : e,
      })
    );

    console.log(arr);

    form.setFieldsValue({
      id: data?.id,
      type: data?.type,
      number: arr,
      chatbot_id: data?.chatbot_id,
    });
  }

  return (
    <>
      <Button
        disabled={!chatbot}
        type="primary"
        onClick={() => {
          setShow(true);
          getData();
        }}
      >
        Lista de números
      </Button>
      <Modal
        title="Permissão de números"
        confirmLoading={submitLoading}
        visible={show}
        okText="Salvar"
        cancelText="Cancelar"
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setShow(false);
          form.resetFields();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Form.Item hidden name="id" />
          <Form.Item hidden name="chatbot_id" />
          <Form.Item name="type">
            <Select defaultValue="Selecione o tipo da lista">
              <Select.Option value="block">Bloquear</Select.Option>
              <Select.Option value="allow">Permitir</Select.Option>
            </Select>
          </Form.Item>
          <Row style={{ marginBottom: "1em" }}>
            <Text>Formato WhatsApp Ex.: +55DDD#########</Text>
          </Row>
          <Form.List name="number">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row
                    key={key}
                    style={{ display: "flex", marginBottom: 8, width: "100%" }}
                    align="baseline"
                    gutter={12}
                  >
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, "type"]}
                        rules={[
                          { required: true, message: "Selecione o tipo" },
                        ]}
                      >
                        <Select placeholder="Selecione o tipo do número">
                          <Select.Option value="whatsapp:">
                            <WhatsAppOutlined /> WhatsApp
                          </Select.Option>
                          <Select.Option value="telegram">
                            <Icon component={FaTelegramPlane} /> Telegram
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Space align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "number"]}
                          rules={[{ required: true, message: "Falta número" }]}
                        >
                          <Input placeholder="Número ou ID Telegram" />
                        </Form.Item>
                        <DeleteOutlined onClick={() => remove(name)} />
                      </Space>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar Número
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};
