import { Center, Heading, Image, Link } from "@chakra-ui/react";
import logo from "../../assets/img/logo_full.png";

export const Construction = () => {
  return (
    <Center minH="100vh" bg="mediumseagreen">
      <Center
        p="2em"
        w="500px"
        h="500px"
        bg="#efefef"
        rounded="15px"
        flexDir="column"
        justifyContent="start"
        shadow="0 0 15px #0000004f"
      >
        <Image src={logo} mb="3em" />
        <Heading fontFamily="rubik" fontWeight="400">
          Estamos em construção!
        </Heading>
        <Heading size="md" mt="1em" fontWeight="400">
          Conheça a{" "}
          <Link href="https://www.darede.com.br/" color="mediumseagreen">
            Darede
          </Link>{" "}
          enquanto isso!
        </Heading>
        <Heading textAlign="center" size="md" mt="1em" fontWeight="400">
          Retorne em breve para conhecer nossa plataforma!
        </Heading>
      </Center>
    </Center>
  );
};
