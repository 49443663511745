import styled from "@emotion/styled";

export const CustomFooter = styled.footer`
  background-color: black;
`;

export const CustomFooterInfo = styled.div`
  color: white;
  width: 360px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  & a {
    color: #198754;
    font-size: 1em;
    text-decoration: none;
  }
  & > div > span > p {
    line-height: 50px;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
  }
  & h4 {
    margin-bottom: 20px;
    color: #fff;
  }
  & p {
    margin-bottom: 20px;
  }
`;

export const CustomVoidButton = styled.button`
  background-color: transparent;
  border: 1px solid white;
  border-radius: 40px;
  color: white;
  width: 120px;
  height: 40px;
  outline: none;
  transition: all 0.5s;
  &:hover {
    background-color: #fff;
    cursor: pointer;
    color: black;
  }
`;

export const CustomFooterTop = styled.div`
  justify-content: space-around;
  background-color: #198754;
  align-items: center;
  text-align: center;
  min-height: 80px;
  font-weight: 900;
  flex-wrap: wrap;
  color: white;
  display: flex;
`;

export const CustomSocialIcon = styled.div`
  width: 35px;
  height: 35px;
  transition: all 0.5s;
  background-color: #198754;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  & a {
    transition: all 0.5s;
    color: black;
    font-weight: 900;
    font-size: 1.3em;
    text-decoration: none;
    &:hover {
      color: #fff;
      cursor: pointer;
    }
  }
  &:hover {
    cursor: pointer;
    color: black;
  }
`;

export const CustomFooterBody = styled.div`
  justify-content: space-around;
  background-color: black;
  align-items: center;
  min-height: 350px;
  flex-wrap: wrap;
  display: flex;
`;

export const CustomFooterBottom = styled.div`
  justify-content: space-around;
  background-color: #0e0e0e;
  align-items: center;
  min-height: 80px;
  flex-wrap: wrap;
  text-align: center;
  color: #7d7d7d;
  display: flex;
  & a {
    color: #7d7d7d;
    text-decoration: none;
  }
`;
