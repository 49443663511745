import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { DefaultHeader } from "../../components/DefaultHeader";
import { useAuth } from "../../context/amplify";
import {
  Layout,
  Card,
  Menu,
  Row,
  Col,
  Form,
  Input,
  Button,
  Drawer,
  message,
  Space,
} from "antd";
import {
  ArrowLeftOutlined,
  RobotOutlined,
  LoadingOutlined,
  SendOutlined,
  UserOutlined,
  ApiOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { TransferModal } from "../../components/Modals/Chatonline/TransferModal";
import { FinishModal } from "../../components/Modals/Chatonline/FinishModal";

const URL = process.env.REACT_APP_AWS_API_SOCKET_GATEWAY_URL;
const socket = new WebSocket(URL);

export const ChatOnline = () => {
  const [reloadMessage, setReloadMessage] = useState(false);
  const [connectionId, setConnectionId] = useState("");
  const [currentRoom, setCurrentRoom] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [rooms, setRooms] = useState([]);
  const { Content, Sider } = Layout;
  const { state } = useLocation();
  const [form] = Form.useForm();
  const auth = useAuth();

  const getRooms = async () => {
    setLoading(true);
    const { data } = await API.get(
      process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT_NAME,
      "/read/room/0"
    );

    const chats = data.Items.filter(
      (e) =>
        localStorage.getItem("dataAdmin") === e.corporate_id?.toString() &&
        e?.status_human === 2 &&
        e.transferred_from === auth.userData?.id
    );

    setLoading(false);
    setRooms(chats);

    const roomConnections = await API.get(
      process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT_NAME,
      "/read/room-connections/0"
    );

    const getConnectionId = roomConnections.data.Items.filter(
      (e) => e?.email === auth.userData?.email
    );

    if (getConnectionId.length > 0) {
      setConnectionId(getConnectionId[0].id_connection_employee);
    }
  };

  const serviceEmail = auth.userData?.email;

  socket.onmessage = (event) => {
    setReloadMessage(true);
    const msgs = messages;
    if (JSON.parse(event.data).publicMessage !== undefined) {
      msgs.push(JSON.parse(event.data));
      msgs.reverse();
      setMessages(msgs);
    }
    setReloadMessage(false);
  };

  const sendMessageToClient = async (formData) => {
    socket.send(
      JSON.stringify({
        action: "sendPublic",
        connectionId: connectionId,
        message: formData.messageText,
        room_name: currentRoom,
      })
    );

    form.resetFields();

    const { data } = await API.get(
      process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT_NAME,
      "/read/room-connections/0"
    );

    const roomConnection = data.Items.filter(
      (e) => e.room_connection?.toString() === currentRoom?.toString()
    );

    if (roomConnection[0].id_connection_employee !== serviceEmail) {
      await API.put(
        process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT_NAME,
        "/update/room-connections/" + roomConnection[0].id,
        {
          body: {
            id_connection_employee: serviceEmail,
            id_connection_user: roomConnection[0].id_connection_user,
            message_origin: roomConnection[0].message_origin,
            room_connection: roomConnection[0].room_connection,
          },
        }
      );
    }

    await API.post(
      process.env.REACT_APP_AWS_API_WEBHOOK_GATEWAY_ENDPOINT_NAME,
      "/webhook/receiveMessage",
      {
        body: {
          messageText: formData.messageText,
          cellphone_user: currentRoom,
          message_origin: roomConnection[0].message_origin,
        },
      }
    );
  };

  const instructions = [
    {
      message: "Agora, selecione na esquerda um usuário para atender",
      side: "right",
    },
    {
      message: "Clique no botão 'Conectar-se'",
      side: "right",
    },
    {
      message: "As mensagens do usuário aparecerão deste lado",
      side: "left",
    },
    {
      message: "As suas mensagens aparecerão deste lado",
      side: "right",
    },
    {
      message: "Acima você verá a identificação do usuário",
      side: "right",
    },
  ];

  useEffect(() => {
    setCurrentRoom(state?.room?.cellphone_user);
    async function fetchData() {
      await getRooms();
    }
    fetchData();

    setTimeout(
      () =>
        socket.send(
          JSON.stringify({
            action: "setName",
            name: serviceEmail,
          })
        ),
      300000
    );
  }, []);

  const messageHistory = (messages) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ maxWidth: "80%", display: "flex" }}>
            <p
              style={{
                width: "100%",
                margin: "2em 0",
                display: "flex",
                padding: ".5em 1em",
                color: "#adadae",
                alignItems: "center",
                flexDirection: "row",
                wordBreak: "break-word",
              }}
            >
              <UpOutlined style={{ margin: "0 .5em" }} />
              Histórico de mensagens com o bot
              <UpOutlined style={{ margin: "0 .5em" }} />
            </p>
          </div>
        </div>
        {messages.map((e) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ maxWidth: "80%" }}>
                  <p
                    style={{
                      width: "100%",
                      padding: ".5em 1em",
                      borderRadius: "15px",
                      color: "whitesmoke",
                      wordBreak: "break-word",
                      margin: ".5em 0",
                      backgroundColor: "#464646",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {e.user_message.message}
                  </p>
                  <span
                    style={{
                      fontColor: "#7d7d7d",
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: "10px",
                    }}
                  >
                    {format(new Date(e.user_message?.timestamp), "HH:mm:ss")}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ maxWidth: "80%" }}>
                  <p
                    style={{
                      width: "100%",
                      padding: ".5em 1em",
                      borderRadius: "15px",
                      color: "whitesmoke",
                      wordBreak: "break-word",
                      margin: ".5em 0",
                      backgroundColor: "#0f9347",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {e.bot_message}
                  </p>
                  <span
                    style={{
                      fontColor: "#7d7d7d",
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: "10px",
                    }}
                  >
                    {format(new Date(e.timestamp), "HH:mm:ss")}
                  </span>
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <DefaultHeader collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        {window.innerWidth <= 500 ? (
          <Drawer
            bodyStyle={{
              backgroundColor: "#3c4b64",
            }}
            closable={false}
            placement="left"
            key="left"
            onClose={() => setCollapsed(false)}
            visible={collapsed}
            width="300"
          >
            <Menu
              defaultSelectedKeys={[
                state?.room?.cellphone_user?.toString() === undefined ||
                currentRoom === undefined ||
                currentRoom === "Instruções"
                  ? "Instruções"
                  : state?.room?.cellphone_user?.toString(),
              ]}
              onSelect={(i) => setCurrentRoom(i.key)}
              theme="dark"
              style={{ backgroundColor: "#3c4b64" }}
            >
              <Menu.Item key={state?.page} icon={<ArrowLeftOutlined />}>
                <Link to={state?.page}>Voltar</Link>
              </Menu.Item>
              <Menu.Item key="Instruções" icon={<ApiOutlined />}>
                <Menu.Item key="Conectar">
                  <Button
                    onClick={() => {
                      socket.send(
                        JSON.stringify({
                          action: "setName",
                          name: serviceEmail,
                        })
                      );
                      socket.onmessage = (event) => {
                        if (JSON.parse(event.data).method === "setName") {
                          setConnectionId(JSON.parse(event.data).connectionId);
                          message.success(
                            "Conectado com sucesso! ID da conexão: " +
                              JSON.parse(event.data).connectionId
                          );
                        }
                      };
                    }}
                    style={{
                      minWidth: "30%",
                      display: "flex",
                      alignSelf: "center",
                      borderRadius: "20px",
                      justifyContent: "center",
                      margin: "20px",
                    }}
                    type="primary"
                  >
                    Conectar-se
                  </Button>
                </Menu.Item>
                Instruções
              </Menu.Item>
              {rooms.length === 0 && loading === false ? (
                <Menu.Item disabled icon={<RobotOutlined />}>
                  Não há clientes aguardando por atendimento
                </Menu.Item>
              ) : loading === true ? (
                <Menu.Item disabled icon={<LoadingOutlined />}>
                  Carregando
                </Menu.Item>
              ) : (
                rooms.map((e) => {
                  return (
                    <Menu.Item
                      key={e.cellphone_user?.toString()}
                      icon={<UserOutlined />}
                    >
                      {e.cellphone_user} -{" "}
                      {e.messages[e.messages.length - 1].user_message.message}
                    </Menu.Item>
                  );
                })
              )}
            </Menu>
          </Drawer>
        ) : (
          <Sider
            style={{ backgroundColor: "#3c4b64" }}
            collapsible
            trigger={null}
            width="330"
            collapsed={collapsed}
          >
            <Menu
              defaultSelectedKeys={[
                state?.room?.cellphone_user?.toString() === undefined ||
                currentRoom === undefined ||
                currentRoom === "Instruções"
                  ? "Instruções"
                  : state?.room?.cellphone_user?.toString(),
              ]}
              onSelect={(i) => setCurrentRoom(i.key)}
              theme="dark"
              style={{ backgroundColor: "#3c4b64" }}
            >
              <Menu.Item key={state?.page} icon={<ArrowLeftOutlined />}>
                <Link to={state?.page}>Voltar</Link>
              </Menu.Item>
              <Menu.Item key="Instruções" icon={<ApiOutlined />}>
                Instruções
              </Menu.Item>
              <Button
                onClick={() => {
                  socket.send(
                    JSON.stringify({
                      action: "setName",
                      name: serviceEmail,
                    })
                  );
                  socket.onmessage = (event) => {
                    if (JSON.parse(event.data).method === "setName") {
                      setConnectionId(JSON.parse(event.data).connectionId);
                      message.success(
                        "Conectado com sucesso! ID da conexão: " +
                          JSON.parse(event.data).connectionId
                      );
                    }
                  };
                }}
                style={{
                  minWidth: "90%",
                  display: "flex",
                  alignSelf: "center",
                  borderRadius: "20px",
                  margin: "20px",
                  justifyContent: "center",
                }}
                type="primary"
              >
                Conectar-se
              </Button>
              {rooms.length === 0 && loading === false ? (
                <Menu.Item disabled icon={<RobotOutlined />}>
                  Não há clientes aguardando por atendimento
                </Menu.Item>
              ) : loading === true ? (
                <Menu.Item disabled icon={<LoadingOutlined />}>
                  Carregando
                </Menu.Item>
              ) : (
                rooms.map((e) => {
                  return (
                    <Menu.Item
                      key={e.cellphone_user?.toString()}
                      icon={<UserOutlined />}
                    >
                      {e.cellphone_user} -{" "}
                      {e.messages[e.messages.length - 1].user_message.message}
                    </Menu.Item>
                  );
                })
              )}
            </Menu>
          </Sider>
        )}
        <Content style={{ padding: "2em" }}>
          <Card
            title={
              <Row justify="space-between">
                <Col>
                  <UserOutlined /> -{" "}
                  {currentRoom === "Instruções" ||
                  currentRoom === "" ||
                  currentRoom === undefined
                    ? "Instruções"
                    : currentRoom}
                </Col>
                <Space>
                  <TransferModal
                    getRooms={() => getRooms()}
                    room={
                      rooms.filter(
                        (e) =>
                          e?.cellphone_user?.toString() ===
                          currentRoom?.toString()
                      )[0]
                    }
                  />
                  <FinishModal
                    getRooms={() => getRooms()}
                    messages={messages.filter((e) => e?.room === currentRoom)}
                    room={
                      rooms.filter(
                        (e) =>
                          e?.cellphone_user?.toString() ===
                          currentRoom?.toString()
                      )[0]
                    }
                  />
                </Space>
              </Row>
            }
            style={{
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              borderRadius: "20px",
            }}
          >
            <Content
              id="messageBody"
              style={{
                display: "flex",
                height: "64vh",
                overflowY: "auto",
                padding: "1em",
                borderRadius: "20px",
                flexDirection: "column-reverse",
                boxShadow: "0 0 10px rgba(0,0,0,0.075)",
                scrollbarGutter: "stable",
              }}
            >
              {/* Instruções */}
              {currentRoom === "Instruções" || currentRoom === undefined ? (
                <>
                  {instructions.map((e) => {
                    if (e.side === "right") {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div style={{ maxWidth: "80%" }}>
                            <p
                              style={{
                                width: "100%",
                                padding: ".5em 1em",
                                borderRadius: "15px",
                                color: "whitesmoke",
                                wordBreak: "break-word",
                                margin: ".5em 0",
                                backgroundColor: "#0f9347",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {e.message}
                            </p>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div style={{ maxWidth: "80%" }}>
                            <p
                              style={{
                                width: "100%",
                                padding: ".5em 1em",
                                borderRadius: "15px",
                                color: "whitesmoke",
                                wordBreak: "break-word",
                                margin: ".5em 0",
                                backgroundColor: "#464646",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {e.message}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  })}
                </>
              ) : null}
              {/* Mensagens do WebSocket */}
              {reloadMessage === true
                ? "Carregando novas mensagens..."
                : messages
                    .sort(function (a, b) {
                      return new Date(b?.timestamp) - new Date(a?.timestamp);
                    })
                    .filter(
                      (e) => currentRoom?.toString() === e?.room?.toString()
                    )
                    .map((e) => {
                      if (e?.publicMessage?.split(":")[0] === serviceEmail) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div style={{ maxWidth: "80%" }}>
                              <p
                                style={{
                                  width: "100%",
                                  padding: ".5em 1em",
                                  borderRadius: "15px",
                                  color: "whitesmoke",
                                  wordBreak: "break-word",
                                  margin: ".5em 0",
                                  backgroundColor: "#0f9347",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {e?.publicMessage?.split(":")[1]}
                              </p>
                              <span
                                style={{
                                  fontColor: "#7d7d7d",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  fontSize: "10px",
                                }}
                              >
                                {format(new Date(e?.timestamp), "HH:mm:ss")}
                              </span>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div style={{ maxWidth: "80%" }}>
                              <p
                                style={{
                                  width: "100%",
                                  padding: ".5em 1em",
                                  borderRadius: "15px",
                                  color: "whitesmoke",
                                  wordBreak: "break-word",
                                  margin: ".5em 0",
                                  backgroundColor: "#464646",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {e.publicMessage.split(":")[1]}
                              </p>
                              <span
                                style={{
                                  fontColor: "#7d7d7d",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  fontSize: "10px",
                                }}
                              >
                                {format(new Date(e?.timestamp), "HH:mm:ss")}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    })}
              {/* Mensagens do Historico */}
              {[null, undefined].includes(state?.room?.messages) === false &&
              currentRoom === state.room.cellphone_user
                ? messageHistory(
                    state?.room?.messages.sort(function (a, b) {
                      return (
                        new Date(b?.user_message.timestamp) -
                        new Date(a?.timestamp)
                      );
                    })
                  )
                : rooms.filter(
                    (e) => currentRoom === e.cellphone_user?.toString()
                  )[0]?.messages.length > 0
                ? messageHistory(
                    rooms
                      .filter(
                        (e) => currentRoom === e.cellphone_user?.toString()
                      )[0]
                      ?.messages.sort(function (a, b) {
                        return (
                          new Date(b?.user_message.timestamp) -
                          new Date(a?.timestamp)
                        );
                      })
                  )
                : null}
            </Content>
            <Form form={form} onFinish={sendMessageToClient}>
              <Row justify="center" style={{ paddingTop: "2em" }}>
                <Col span={22}>
                  <Form.Item
                    name="messageText"
                    style={{
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <Input.TextArea
                      disabled={
                        currentRoom === "Instruções" ||
                        currentRoom === undefined
                          ? true
                          : false
                      }
                      onPressEnter={() => form.submit()}
                      placeholder="Digite aqui sua mensagem!"
                      autoSize={{ minRows: 1, maxRows: 2 }}
                      style={{
                        margin: "0",
                        textAlign: "left",
                        borderRadius: "15px 0 0px 15px",
                        resize: "none",
                        borderRight: "none",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Button
                    disabled={
                      currentRoom === "Instruções" || currentRoom === undefined
                        ? true
                        : false
                    }
                    htmlType="submit"
                    style={{
                      borderLeft: "none",
                      borderRadius: "0px 15px 15px 0px",
                    }}
                  >
                    <SendOutlined />
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};
