import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Modal,
  Radio,
  message,
  Progress,
} from "antd";
import { API } from "aws-amplify";
import AWS from "aws-sdk";
import { getYear, getMonth, getDate } from "date-fns";
import axios from "axios";
import { useAuth } from "../../../context/amplify";

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME;
const REGION = process.env.REACT_APP_AWS_BUCKET_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_BUCKET_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_BUCKET_SECRET_ACCESS_KEY,
});

export const AddClientModal = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState();
  const [form] = Form.useForm();
  const { getClients } = props;
  const auth = useAuth();

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (data) => {
    const url = "https://publica.cnpj.ws/cnpj/" + data.cnpj;

    let dataCnpj;

    try {
      dataCnpj = await axios.get(url);

      const params = {
        ACL: "public-read",
        Body: selectedFile,
        Bucket: S3_BUCKET,
        Key:
          getYear(new Date()) +
          "/" +
          (getMonth(new Date()) + 1) +
          "/" +
          getDate(new Date()) +
          "/" +
          selectedFile.name,
      };

      await API.post(
        process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT_NAME,
        "/create/client",
        {
          body: {
            cnpj: data.cnpj,
            free_tier: data.free_tier,
            nome: dataCnpj.data.razao_social,
            active: 1,
            country: dataCnpj.data.estabelecimento.pais.nome,
            phone:
              dataCnpj.data.estabelecimento.ddd1 +
              dataCnpj.data.estabelecimento.telefone1,
            email: dataCnpj.data.estabelecimento.email,
            segment:
              dataCnpj.data.estabelecimento.atividade_principal.descricao,
            logo:
              "https://meu-bot-files-corporates.s3.amazonaws.com/" +
              getYear(new Date()) +
              "/" +
              (getMonth(new Date()) + 1) +
              "/" +
              getDate(new Date()) +
              "/" +
              selectedFile.name,
            facebook_id: data.facebook_id,
          },
        }
      );

      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err) => {
          message.success("Feito upload do arquivo com sucesso!");
          if (err) console.log(err);
        });

      await API.post(
        process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT_NAME,
        "/create/audits/",
        {
          body: {
            name: "Cliente Adicionado",
            description: `${auth.userData.email} adicionou o cliente ${dataCnpj.data.razao_social}.`,
            username: auth.userData.email,
          },
        }
      );

      message.success("Cliente cadastrado com sucesso! 😀");
      form.resetFields();
      await getClients();
      setShow(false);
    } catch (error) {
      console.log(error);
      message.error("Ops... Ocorreu um erro inesperado! 😥");
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setShow(true);
        }}
      >
        Adicionar Cliente
      </Button>
      <Modal
        title="Adicione um Cliente"
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setShow(false);
          form.resetFields();
        }}
        visible={show}
        okText="Adicionar Cliente"
        cancelText="Cancelar"
      >
        <Row justify="center">
          <Col span={24}>
            <Form
              form={form}
              requiredMark={false}
              layout="vertical"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="cnpj"
                label="CNPJ"
                rules={[{ required: true, message: "Insira um cnpj" }]}
              >
                <Input maxLength={14} placeholder="Digite o CNPJ" />
              </Form.Item>
              <Form.Item
                name="facebook_id"
                label="Facebook Id"
                rules={[
                  {
                    required: true,
                    message: "Insira o Facebook Id da empresa",
                  },
                ]}
              >
                <Input placeholder="Digite o Facebook Id da empresa" />
              </Form.Item>
              <Row align="middle" justify="center">
                <Progress type="line" percent={progress} />
              </Row>
              <Form.Item>
                <Row
                  style={{ marginTop: "2em" }}
                  align="middle"
                  justify="start"
                >
                  <Col>
                    <Button type="primary">
                      <label htmlFor="file">Selecionar o logotipo...</label>
                    </Button>
                  </Col>
                  <Col>
                    <input
                      type="file"
                      name="file"
                      id="file"
                      accept=".jpeg, .jpg, .png"
                      onChange={handleFileInput}
                      style={{ display: "none" }}
                    />

                    <Col style={{ marginLeft: ".5em" }}>
                      {selectedFile?.nome}
                    </Col>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="free_tier" label="Free Tier">
                <Radio.Group>
                  <Radio value={1}>Sim</Radio>
                  <Radio value={0}>Não</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
