import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import { useRef, useEffect, useLayoutEffect } from "react";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

function am4themes_myTheme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [am4core.color("mediumseagreen")];
  }
}

//Themes
am4core.useTheme(am4themes_myTheme);
am4core.useTheme(am4themes_animated);

export const GraphicWords = (props) => {
  let chartRef = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create("chartdivwords", am4plugins_wordCloud.WordCloud);
    let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());

    series.data = props.data;

    series.accuracy = 4;
    series.step = 15;
    series.rotationThreshold = 0.7;
    series.minWordLength = 2;
    series.labels.template.tooltipText = "{word}";
    series.labels.template.margin(4, 4, 4, 4);
    series.maxFontSize = am4core.percent(30);

    series.dataFields.word = "text";

    series.colors = new am4core.ColorSet();
    series.colors.passOptions = {}; // makes it loop

    //series.labelsContainer.rotation = 45;
    series.angles = [0, -90];
    series.fontWeight = "500";

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, []);

  useEffect(() => {
    chartRef.current.data = props.data;
  }, [props.data]);

  return (
    <div
      style={{
        width: props.width,
        height: props.height,
      }}
      id="chartdivwords"
    />
  );
};
