import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import "./App.css";
import { AuthProvider } from "./context/amplify";
import { ChakraProvider } from "@chakra-ui/react";
import Router from "./routes/Router";
Amplify.configure(aws_exports);

function App() {
  return (
    <AuthProvider>
      <ChakraProvider>
        <Router />
      </ChakraProvider>
    </AuthProvider>
  );
}

export default App;
