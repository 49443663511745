export const getToken = (user) => {
  return user.signInUserSession.idToken.jwtToken;
};

export function getPayload(user) {
  if (user.signInUserSession !== undefined) {
    return user.signInUserSession.idToken.payload;
  }
  return user.idToken.payload;
}

export function mapUserDataFromPayload(payload) {
  return {
    email: payload.email,
    id: payload.sub,
    corporate_id: payload["custom:corporate_id"],
    role_id: payload["custom:role_id"],
    phone: payload["phone_number"],
  };
}

export function hasPermissionToAccess(userData, page, features) {
  let userPermissions = [];

  if (userData != null && features !== undefined) {
    userPermissions = features;
  }

  if (userPermissions.includes(page)) {
    return true;
  }

  return false;
}
