import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useRef, useEffect, useLayoutEffect } from "react";

function am4themes_myTheme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [am4core.color("mediumseagreen")];
  }
}

//Themes
am4core.useTheme(am4themes_myTheme);
am4core.useTheme(am4themes_animated);

const GraphicPie = (props) => {
  let chartRef = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.PieChart);

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "numbers";
    pieSeries.dataFields.category = "group";

    // Let's cut a hole in our Pie chart the size of 30% the radius
    chart.innerRadius = am4core.percent(0);

    // Put a thick white border around each Slice
    pieSeries.slices.template.stroke = am4core.color("white");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    // change the cursor on hover to make it apparent the object can be interacted with
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: "cursor",
        value: "pointer",
      },
    ];

    pieSeries.alignLabels = false;
    pieSeries.labels.template.disabled = true;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 15;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    pieSeries.ticks.template.disabled = true;

    // Create a base filter effect (as if it's not there) for the hover to return to
    let shadow = pieSeries.slices.template.filters.push(
      new am4core.DropShadowFilter()
    );
    shadow.opacity = 0;

    // Create hover state
    let hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

    // Slightly shift the shadow and make it more prominent on hover
    let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;

    // Add a legend
    // chart.legend = new am4charts.Legend();

    chart.data = props.data;

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, []);

  useEffect(() => {
    chartRef.current.data = props.data;
  }, [props.data]);

  return (
    <div
      style={{
        width: props.width,
        height: props.height,
      }}
      id="chartdiv"
    />
  );
};

export default GraphicPie;
