import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import React from "react";
import { Login } from "../pages/Login";
import { Loading } from "../pages/Loading";
import { useAuth } from "../context/amplify";
import { ForgotPassword } from "../pages/ForgotPassword";
import { hasPermissionToAccess } from "../utils/userData";
import { CodeVerification } from "../pages/CodeVerification";
import { FirstLoginConfirm } from "../pages/FirstLoginConfirm";
import { Home } from "../pages/Home";
import { HomeAdmin } from "../pages/Admin/Home";
import { Graphics } from "../pages/Graphics";
import { Chatbot } from "../pages/Chatbot";
import { ManageApi } from "../pages/ManageApi";
import { Keywords } from "../pages/Keywords";
import { Chats } from "../pages/Chats";
import { Users } from "../pages/Users";
import { ChatOnline } from "../pages/ChatOnline";
import { Chat } from "../pages/Chat";
import { UsersAdmin } from "../pages/Admin/Users";
import { Links } from "../pages/Links";
import { Audit } from "../pages/Admin/Audit";
import { HomePublic } from "../pages/HomePublic";
import { ContactsPublic } from "../pages/ContactsPublic";
import { Construction } from "../pages/Construction";

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (auth.isAuthenticating) {
    return <Loading />;
  }

  if (auth.isAuthenticated === false && auth.isAuthenticating === false) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}

function PublicPath({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (auth.isAuthenticating === true && !auth.isAuthenticated) {
    return <Loading />;
  }

  if (auth.isAuthenticating === false && auth.isAuthenticated) {
    return <Navigate to="/home" state={{ from: location }} />;
  }

  return children;
}

function HasPermission({ children, page }) {
  let auth = useAuth();
  let location = useLocation();

  if (!hasPermissionToAccess(auth.userData, page, auth.userPermissions)) {
    return <Navigate to="/home" state={{ from: location }} />;
  }

  return children;
}

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Construction />} />
        {/* <Route exact path="/" element={<HomePublic />} />
        <Route path="/contatos" element={<ContactsPublic />} />
        <Route
          path="/login"
          element={
            <PublicPath>
              <Login />
            </PublicPath>
          }
        />
        <Route path="/links" element={<Links />} />
        <Route
          path="/recuperar-senha"
          element={
            <PublicPath>
              <ForgotPassword />
            </PublicPath>
          }
        />
        <Route
          path="/verificarcodigo"
          element={
            <PublicPath>
              <CodeVerification />
            </PublicPath>
          }
        />
        <Route
          path="/primeirologin"
          element={
            <PublicPath>
              <FirstLoginConfirm />
            </PublicPath>
          }
        />
        <Route
          path="/home"
          element={
            <RequireAuth>
              <HasPermission page="home">
                <Home />
              </HasPermission>
            </RequireAuth>
          }
        />
        <Route
          path="/graficos"
          element={
            <RequireAuth>
              <HasPermission page="graficos">
                <Graphics />
              </HasPermission>
            </RequireAuth>
          }
        />
        <Route
          path="/chatbot"
          element={
            <RequireAuth>
              <HasPermission page="chatbot">
                <Chatbot />
              </HasPermission>
            </RequireAuth>
          }
        />
        <Route
          path="/api"
          element={
            <RequireAuth>
              <HasPermission page="api">
                <ManageApi />
              </HasPermission>
            </RequireAuth>
          }
        />
        <Route path="/chatonline" element={<ChatOnline />} />
        <Route
          path="/chat"
          element={
            <RequireAuth>
              <HasPermission page="chatonline">
                <Chat />
              </HasPermission>
            </RequireAuth>
          }
        />
        <Route
          path="/keywords"
          element={
            <RequireAuth>
              <HasPermission page="keywords">
                <Keywords />
              </HasPermission>
            </RequireAuth>
          }
        />
        <Route
          path="/conversas"
          element={
            <RequireAuth>
              <HasPermission page="conversas">
                <Chats />
              </HasPermission>
            </RequireAuth>
          }
        />
        <Route
          path="/usuarios"
          element={
            <RequireAuth>
              <HasPermission page="usuarios">
                <Users />
              </HasPermission>
            </RequireAuth>
          }
        />
        <Route
          path="/admin/users"
          element={
            <RequireAuth>
              <HasPermission page="admin">
                <UsersAdmin />
              </HasPermission>
            </RequireAuth>
          }
        />
        <Route
          path="/admin/home"
          element={
            <RequireAuth>
              <HasPermission page="admin">
                <HomeAdmin />
              </HasPermission>
            </RequireAuth>
          }
        />
        <Route
          path="/admin/logs"
          element={
            <RequireAuth>
              <HasPermission page="admin">
                <Audit />
              </HasPermission>
            </RequireAuth>
          }
        /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
