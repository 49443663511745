import { Auth, Hub } from "aws-amplify";
import { useContext, createContext, useState, useEffect } from "react";
import { getPayload, mapUserDataFromPayload } from "../utils/userData";
import Axios from "axios";
import { generateToken } from "../utils/token";

const authContext = createContext({});

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

export const getInfo = async (route) => {
  const token = await generateToken();

  const { data } = await Axios.get(
    process.env.REACT_APP_AWS_API_GATEWAY_URL + route,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return data.data;
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [corporateLogo, setCorporateLogo] = useState("");

  const signIn = async (email, password) => {
    try {
      const userSignIn = await Auth.signIn(email, password);

      let user = await Auth.currentSession();

      setUserData(mapUserDataFromPayload(getPayload(user)));

      if (
        mapUserDataFromPayload(getPayload(user)).corporate_id.toString() ===
        process.env.REACT_APP_CORP_ID
      ) {
        localStorage.setItem(
          "dataAdmin",
          process.env.REACT_APP_CORP_ID
        );
      } else if (
        mapUserDataFromPayload(getPayload(user)).corporate_id.toString() !==
        process.env.REACT_APP_CORP_ID
      ) {
        localStorage.setItem(
          "dataAdmin",
          mapUserDataFromPayload(getPayload(user)).corporate_id.toString()
        );
      }

      const data = await getInfo("/read/permissions/0");

      const filteredPermissions = data.Items.filter(
        (e) =>
          e.responsability_id?.toString() ===
          mapUserDataFromPayload(getPayload(user))?.role_id?.toString()
      );
      
      setUserPermissions(filteredPermissions[0].feature);
      setUser(user);
      setIsAuthenticated(true);

      return userSignIn;
    } catch (error) {
      if (error === "No current user") {
        return "No current user";
      }
      console.log(error);
    }
  };

  const completePassword = async (newPassword) => {
    const userSignIn = await Auth.signIn(
      localStorage.getItem("email"),
      localStorage.getItem("password")
    );

    localStorage.clear();

    return Auth.completeNewPassword(userSignIn, newPassword);
  };

  const signOut = () => {
    return Auth.signOut().then(() => {
      localStorage.clear();
      setUser(null);
      setUserData(null);
      setIsAuthenticated(false);
      setUserPermissions(null);
    });
  };

  const sendPasswordResetEmail = (email) => {
    return Auth.forgotPassword(email);
  };

  const confirmPasswordReset = (code, username, password) => {
    return Auth.forgotPasswordSubmit(username, code, password);
  };

  const recovery = (username) => {
    return Auth.forgotPassword(username);
  };

  const recoveryNewPassword = (username, code, newPass) => {
    return Auth.forgotPasswordSubmit(username, code, newPass);
  };

  function hub() {
    Hub.listen("auth", (data) => {
      const { payload } = data;
      console.log(payload);
    });
  }

  async function onLoad() {
    hub();
    try {
      let user = await Auth.currentSession();
      setUser(user);

      const data = await getInfo("/read/permissions/0");

      const corporate = await getInfo(
        "/read/client/" + mapUserDataFromPayload(getPayload(user)).corporate_id
      );

      setCorporateLogo(corporate?.Item?.logo);

      const filteredPermissions = data.Items.filter(
        (e) =>
          e.responsability_id?.toString() ===
          mapUserDataFromPayload(getPayload(user)).role_id.toString()
      );

      let featArr = [];

      filteredPermissions.forEach((e) => {
        featArr.push(e.feature);
      });

      setUserPermissions(filteredPermissions[0].feature);
      setUserData(mapUserDataFromPayload(getPayload(user)));
      setIsAuthenticated(true);
    } catch (e) {
      setUser(null);
      setIsAuthenticated(false);
    }

    setIsAuthenticating(false);
  }

  useEffect(() => {
    onLoad();
    hub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // obj com tudo relacionado ao auth
  return {
    user,
    setUser,
    signIn,
    isAuthenticating,
    //signup,
    signOut,
    setCorporateLogo,
    corporateLogo,
    sendPasswordResetEmail,
    confirmPasswordReset,
    completePassword,
    isFirstLogin,
    setIsFirstLogin,
    isAuthenticated,
    setIsAuthenticated,
    recovery,
    userData,
    userPermissions,
    recoveryNewPassword,
  };
}
