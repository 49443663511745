import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Grid,
  Input,
  Textarea,
  Flex,
  Select,
  FormControl,
  useToast,
} from "@chakra-ui/react";

import { codes } from "country-calling-code";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { API } from "aws-amplify";
import { celularMask } from "../../utils/mask";

export const ModalSignUp = () => {
  const { register, handleSubmit, control, reset } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [verified, setVerified] = useState(false);
  const toast = useToast();

  const sendData = async (data) => {
    if (!verified) {
      return toast({
        description: "Confirme que você não é um robô.",
        status: "error",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
    }

    if (
      data.email.includes("@test") ||
      data.email.includes("@teste") ||
      data.email.includes("@testing")
    ) {
      return toast({
        description: "E-mail com '@teste' não permitido.",
        status: "error",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
    }

    data["telefone"] = "+" + data.code + " " + data.telefone;
    delete data.code;

    await API.post(
      process.env.REACT_APP_AWS_API_GATEWAY_EMAIL_ENDPOINT_NAME,
      "/send/email",
      {
        body: {
          nome: data.name,
          email: data.email,
          telefone: data.telefone,
          empresa: data.company,
          mensagem: data.message,
        },
      }
    );

    toast({
      description:
        "Em breve você receberá um e-mail com mais informações. Seja bem-vindo!",
      status: "success",
      position: "top",
      duration: 5000,
      isClosable: true,
    });

    reset();
  };

  return (
    <>
      <Button
        bg="#D29725"
        padding="10px 20px"
        borderRadius="10px"
        position="relative"
        listStyleType="none"
        color="#fff"
        onClick={onOpen}
        _hover={{ backgroundColor: "#a1751d" }}
      >
        Cadastrar
      </Button>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastre-se</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(sendData)}>
              <Flex direction="column" align="center">
                <Grid width="100%">
                  <Text margin="10px" fontSize="18px" color="#3C4B64">
                    Nome
                  </Text>
                  <Input
                    {...register("name")}
                    placeholder="Digite seu Nome"
                    bg="#F5F5F5"
                    boxShadow="0px 0px 20px rgba(0, 0, 0, 0.15)"
                    borderRadius="12px"
                    required
                    focusBorderColor="#38c072"
                    style={{
                      width: window.innerWidth <= 400 ? "97%" : "100%",
                      height: window.innerWidth <= 400 ? "40px" : "53px",
                    }}
                  ></Input>
                </Grid>
                <Grid width="100%">
                  <Text margin="10px" fontSize="18px" color="#3C4B64">
                    Email
                  </Text>
                  <Input
                    {...register("email")}
                    placeholder="Digite seu Email"
                    bg="#F5F5F5"
                    boxShadow="0px 0px 20px rgba(0, 0, 0, 0.15)"
                    borderRadius="12px"
                    focusBorderColor="#38c072"
                    required
                    style={{
                      width: window.innerWidth <= 400 ? "97%" : "100%",
                      height: window.innerWidth <= 400 ? "40px" : "53px",
                    }}
                  ></Input>
                </Grid>
                <Grid margin="10px" width="100%">
                  <Text fontSize="18px" color="#3C4B64">
                    Telefone
                  </Text>
                  <Flex justify="space-between" align="center">
                    <Grid marginRight="10px">
                      <Select
                        placeholder="Selecione o país"
                        bg="#F5F5F5"
                        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.15)"
                        borderRadius="12px"
                        required
                        focusBorderColor="#38c072"
                        {...register("code")}
                      >
                        {codes.map((e, i) => {
                          return (
                            <option key={i} value={e.countryCodes[0]}>
                              {e.country}
                            </option>
                          );
                        })}
                      </Select>
                    </Grid>
                    <FormControl>
                      <Controller
                        control={control}
                        name="telefone"
                        render={({ field }) => (
                          <Input
                            {...field}
                            isRequired
                            onChange={(e) =>
                              field.onChange(celularMask(e.target.value))
                            }
                            placeholder="Digite seu Telefone"
                            bg="#F5F5F5"
                            boxShadow="0px 0px 20px rgba(0, 0, 0, 0.15)"
                            borderRadius="12px"
                            focusBorderColor="#38c072"
                            style={{
                              width: window.innerWidth <= 400 ? "97%" : "100%",
                              height:
                                window.innerWidth <= 400 ? "40px" : "53px",
                              marginRight:
                                window.innerWidth <= 400 ? "10px" : "0px",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Flex>
                </Grid>
                <Grid width="100%">
                  <Text margin="10px" fontSize="18px" color="#3C4B64">
                    Empresa
                  </Text>
                  <Input
                    {...register("company")}
                    placeholder="Digite seu Empresa"
                    bg="#F5F5F5"
                    required
                    boxShadow="0px 0px 20px rgba(0, 0, 0, 0.15)"
                    borderRadius="12px"
                    focusBorderColor="#38c072"
                    style={{
                      width: window.innerWidth <= 400 ? "97%" : "100%",
                      height: window.innerWidth <= 400 ? "40px" : "53px",
                    }}
                  ></Input>
                </Grid>
                <Grid width="100%">
                  <Text margin="10px" fontSize="18px" color="#3C4B64">
                    Mensagem
                  </Text>
                  <Textarea
                    {...register("message")}
                    placeholder="Digite sua Mensagem"
                    bg="#F5F5F5"
                    boxShadow="0px 0px 20px rgba(0, 0, 0, 0.15)"
                    borderRadius="12px"
                    focusBorderColor="#38c072"
                    style={{
                      width: window.innerWidth <= 400 ? "97%" : "100%",
                      height: window.innerWidth <= 400 ? "40px" : "53px",
                    }}
                  ></Textarea>
                </Grid>
                <Grid require marginTop="40px">
                  <ReCAPTCHA
                    onChange={() => setVerified(!verified)}
                    sitekey="6LdI0lIeAAAAAD0PvCh9QUvsFXCe-8iXWP2dRlia"
                  />
                </Grid>
                <Button
                  bg="#D29725"
                  fontSize="18px"
                  color="#fff"
                  padding="10px 30px"
                  margin="20px"
                  _hover={{ backgroundColor: "#a1751d" }}
                  type="submit"
                >
                  Enviar
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
