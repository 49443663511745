import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { useRef, useLayoutEffect } from "react";
import { useEffect } from "react";

function am4themes_myTheme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [am4core.color("mediumseagreen")];
  }
}

//Themes
am4core.useTheme(am4themes_myTheme);
am4core.useTheme(am4themes_animated);

export const ChatbotFlowGraphic = (props) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.SankeyDiagram);

    chart.hiddenState.properties.opacity = 0;

    chart.data = props.data;

    let hoverState = chart.links.template.states.create("hover");
    hoverState.properties.fillOpacity = 0.6;

    chart.paddingLeft = 100;
    chart.paddingRight = 180;

    chart.dataFields.fromName = "from";
    chart.dataFields.toName = "to";
    chart.dataFields.value = "value";

    chartRef.current = chart;
    return () => {
      chart.dispose();
    };
  }, []);

  useEffect(() => {
    chartRef.current.data = props.data;
  }, [props.data]);

  return (
    <div id="chartdiv" style={{ height: props.height, width: props.width }} />
  );
};
