import Axios from "axios";

export const generateToken = async () => {
  const { data } = await Axios.get(
    process.env.REACT_APP_AWS_API_GATEWAY_URL + "/get/token"
  );

  return data.data;
};

export const generateEmailToken = async () => {
  const { data } = await Axios.get(
    process.env.REACT_APP_AWS_API_GATEWAY_EMAIL_URL + "/get/token"
  );

  return data.data;
};
