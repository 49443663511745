import { bubble as Menu } from "react-burger-menu";
import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { ModalSignUp } from "../Modal";
import { Button, LinkBox, LinkOverlay } from "@chakra-ui/react";
import logo from "../../assets/img/image 10.svg";
import { Img, Flex } from "@chakra-ui/react";

class burger extends React.Component {
  showSettings(event) {
    event.preventDefault();
  }

  render() {
    window.addEventListener("scroll", function () {
      var header = document?.querySelector(".menu");
      header?.classList?.toggle("sticky", window?.scrollY > 0);
    });
    return (
      <>
        <Flex
          style={{
            display: window.innerWidth >= 1000 ? "none" : "flex",
          }}
          align="center"
          height="100px"
          width="100%"
          position="fixed"
          zIndex={4}
        >
          <div
            className="menu"
            style={{
              width: "100%",
              height: "100px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Img
              style={{
                width: window.innerWidth >= 1000 ? "0px" : "120px",
              }}
              width="120px"
              marginLeft="30px"
              src={logo}
            />
          </div>
        </Flex>
        <Menu right width={"100%"} disableAutoFocus>
          <Link to="/">
            <a id="home" className="menu-item">
              Home
            </a>
          </Link>
          <Link to="/contatos">
            <a id="about" className="menu-item">
              Contatos
            </a>
          </Link>
          <a id="contribute" className="menu-item">
            Conheça a Darede
          </a>
          <ModalSignUp />
          <LinkBox
            bg="#373a47"
            padding="0 20px"
            borderRadius="10px"
            position="relative"
            listStyleType="none"
            color="#fff"
            className="menu-item"
            width="90px"
            fontSize="18px"
            marginTop="20px"
          >
            <LinkOverlay href="/login">Login</LinkOverlay>
          </LinkBox>
        </Menu>
      </>
    );
  }
}
export default burger;
